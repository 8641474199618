import React from "react"
import styled from "@emotion/styled"
import { RichText } from "prismic-reactjs"
import ArrowRight from "./arrowRight"
import ProjectImage from "./projectImage"

function Project({ offset, className, project }) {
  const { uid, data: projectData } = project

  const client = RichText.asText(projectData.client.raw)
  const title = RichText.asText(projectData.title.raw)
  // const category = projectData.category.document.data.name || ""
  const logo = projectData.client_logo.fluid
  const slug = uid

  const Section = styled.section``

  return (
    <Section className={className}>
      <ProjectImage
        image={projectData.hero_image}
        to={`/our-work/${slug}`}
        alt={`${client}: ${title}`}
        logo={logo}
        offset={offset}
        slug={slug}
        client={client}
        footer={
          <div className="d-flex d-sm-inline-flex align-items-center flex-nowrap justify-content-between ">
            <span>
              <h4 className="h6 font-weight-normal">
                {`CASE | ${title || client}`}
              </h4>
            </span>
            <div className="h6 white circle">
              <ArrowRight />
            </div>
          </div>
        }
      ></ProjectImage>
    </Section>
  )
}

Project.defaultProps = {}

Project.propTypes = {}

export default Project
