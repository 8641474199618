import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Reveal from "./reveal"

const StyledLogo = styled(Img)`
  width: 180px;
  position: absolute !important;
  top: 15px;
  left: 15px;
  z-index: 10;
  @media (min-width: 768px) {
    top: 30px;
    left: 30px;
  }
`
const Footer = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: right;
  padding: 50px 15px 15px;
  color: #fff !important;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    );
  }
  @media (min-width: 768px) {
    padding: 30px;
  }
`

function ProjectImage({
  image,
  to,
  footer,
  children,
  logo,
  alt,
  offset,
  client,
  slug,
}) {
  if (!image || !image.fluid) {
    return null
  }
  const desktop = image.fluid
  const mobile = image.thumbnails.mobile.fluid

  const sources = [
    mobile,
    {
      ...desktop,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <div className="row row__image">
      <div className="col-12">
        <Reveal offset={offset}>
          <Link
            to={to}
            className="white link--line-under-span"
            style={{
              textDecoration: "underline",
              color: "inherit",
              display: "block",
            }}
          >
            <div className="embed-responsive embed-responsive-21by9 embed-responsive-1by1--sm">
              {logo && (
                <StyledLogo
                  fluid={logo}
                  alt={`Logo | ${client}`}
                  className={`logo--${slug}`}
                />
              )}
              <div className="embed-responsive-item">
                <Img
                  objectFit="cover"
                  objectPosition="50% 50%"
                  fluid={sources}
                  alt={alt || "Chunk Creative Agency - Amsterdam | London"}
                />
                {children}
                {footer && <Footer>{footer}</Footer>}
              </div>
            </div>
          </Link>
        </Reveal>
      </div>
    </div>
  )
}

ProjectImage.defaultProps = {}

ProjectImage.propTypes = {}

export default ProjectImage
